import {
  Close,
  Facebook,
  Reddit,
  Share,
  Telegram,
  X,
} from "@mui/icons-material";
import {
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import {
  blue,
  deepOrange,
  green,
  grey,
  lightBlue,
} from "@mui/material/colors";
import React, { useState } from "react";
import {
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

export default function ShareButtonWithDialog({
  awardUrl,
  awardName,
}) {
  const [showShareDialog, setShowShareDialog] = useState(false);

  const handleBeforeShare = (label) => {
    //@ts-ignore
    window.gtag("event", "feature_use", {
      event_category: "share",
      event_label: `Social Media - ${label}`,
    });
  };

  return (
    <>
      <Dialog
        open={showShareDialog}
        onClose={() => setShowShareDialog(false)}
      >
        <DialogTitle sx={{ mr: "2rem" }}>
          Share Award via...{" "}
          <Close
            aria-label="close"
            onClick={() => setShowShareDialog(false)}
            sx={{
              position: "absolute",
              right: "1rem",
              top: "1rem",
              color: grey[500],
              cursor: "pointer",
            }}
          />
        </DialogTitle>
        <List
          dense
          sx={{
            pt: 0,
            "> * > *": {
              width: "100%",
            },
          }}
        >
          <ListItem disablePadding>
            <FacebookShareButton
              url={awardUrl}
              beforeOnClick={() => handleBeforeShare("Facebook")}
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: blue[600], color: "white" }}>
                    <Facebook />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Facebook" />
              </ListItemButton>
            </FacebookShareButton>
          </ListItem>
          <ListItem disableGutters>
            <TwitterShareButton
              url={awardUrl}
              title={awardName}
              beforeOnClick={() => handleBeforeShare("Twitter")}
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "black", color: "white" }}>
                    <X />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Twitter" />
              </ListItemButton>
            </TwitterShareButton>
          </ListItem>
          <ListItem disableGutters>
            <RedditShareButton
              url={awardUrl}
              title={awardName}
              beforeOnClick={() => handleBeforeShare("Reddit")}
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      bgcolor: deepOrange[500],
                      color: "white",
                    }}
                  >
                    <Reddit />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Reddit" />
              </ListItemButton>
            </RedditShareButton>
          </ListItem>
          <ListItem disableGutters>
            <WhatsappShareButton
              url={awardUrl}
              title={awardName}
              beforeOnClick={() => handleBeforeShare("WhatsApp")}
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar
                    sx={{ bgcolor: green[500], color: "white" }}
                  >
                    <WhatsappIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="WhatsApp" />
              </ListItemButton>
            </WhatsappShareButton>
          </ListItem>
          <ListItem disableGutters>
            <TelegramShareButton
              url={awardUrl}
              title={awardName}
              beforeOnClick={() => handleBeforeShare("Telegram")}
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar
                    sx={{ bgcolor: lightBlue[500], color: "white" }}
                  >
                    <Telegram />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Telegram" />
              </ListItemButton>
            </TelegramShareButton>
          </ListItem>
          <ListItem disableGutters>
            <LineShareButton
              url={awardUrl}
              title={awardName}
              beforeOnClick={() => handleBeforeShare("Line")}
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar
                    sx={{ bgcolor: lightBlue[500], color: "white" }}
                  >
                    <LineIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Line" />
              </ListItemButton>
            </LineShareButton>
          </ListItem>
        </List>
      </Dialog>
      <Button
        variant="text"
        onClick={() => setShowShareDialog(true)}
        startIcon={<Share />}
        disabled={!awardName}
        fullWidth
      >
        Share Award
      </Button>
    </>
  );
}
