import {
  Assistant,
  Download,
  ExpandMore,
  Image,
  Link as LinkIcon,
  TravelExplore,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import html2canvas from "html2canvas";
import React, { useMemo, useRef, useState } from "react";
import { bytesToBase64, downloadUri } from "../utils";
import AwardView from "./AwardView";
import Logo from "./Logo";
import ShareButtonWithDialog from "./ShareButtonWithDialog";

const placeholders = [
  {
    awardName: "Biggest Twat Award",
    awarderName: "the Adjudicator of Twats",
  },
  {
    awardName: "Sweetest Pea Award",
    awarderName: "the Ministry of Sugar",
  },
  {
    awardName: "Greatest of All Time Award",
    awarderName: "the GOAT Authorities",
  },
  {
    awardName: "Witty Monkey Award",
    awarderName: "the Presider of Monkeys",
  },
  {
    awardName: "Sloppy Sloth Award",
    awarderName: "the Chairperson of Daydreams",
  },
  {
    awardName: "Clueless Capybara Award",
    awarderName: "the President of Rodents",
  },
  {
    awardName: "Sharpest Tool in the Shed Award",
    awarderName: "the Warden of DIY",
  },
];

const placeholderChoice = Math.floor(
  Math.random() * placeholders.length
);

const awarderNames = [
  "the Supreme Authority of Awards",
  "the Registry of Legitimate Accolades",
  "the Council of the Wise",
  "the Ministry of Facts",
  "the Administrator of Universal Truths",
  "the Arbiter of Recognition",
  "the Justice of Opinions",
  "the Board of Peculiarities",
  "the Governor of Supreme World Order",
];

export default function AwardForm() {
  const [awardName, setAwardName] = useState("");
  const [awardParams, setAwardParams] = useState({
    awardeeName: "",
    awarderName: "",
    awardDate: null,
    awardType: "",
  });
  const [resultMsg, setResultMsg] = useState("");
  const [showResultMsg, setShowResultMsg] = useState(false);
  const [showOptionalSettings, setShowOptionalSettings] =
    useState(false);

  const screenshotRef = useRef(null);

  const theme = useTheme();

  const awardUrl = useMemo(() => {
    const awardUrl = new URL("/", window.location.href);

    awardUrl.pathname = encodeURIComponent(
      awardName
        .toUpperCase()
        .replaceAll("/", "")
        .trim()
        .replaceAll(" ", "_")
    );

    if (
      (() => {
        for (let eachParam in awardParams) {
          if (awardParams[eachParam]) return true;
        }
        return false;
      })()
    ) {
      const encodedParams = bytesToBase64(
        new TextEncoder().encode(
          JSON.stringify({
            ...awardParams,
            awardDate: awardParams.awardDate?.format("DD MMMM YYYY"),
          })
        )
      );
      awardUrl.searchParams.append("p", encodedParams);
    }

    return awardUrl.href;
  }, [awardName, awardParams]);

  const handleRandomAwarderName = () => {
    setAwardParams((prev) => {
      return {
        ...prev,
        awarderName:
          awarderNames[
            Math.floor(Math.random() * awarderNames.length)
          ],
      };
    });
  };

  const handleCopyButtonClick = async () => {
    //@ts-ignore
    window.gtag("event", "feature_use", {
      event_category: "share",
      event_label: "Copy Link",
    });

    if (navigator.clipboard) {
      await navigator.clipboard.writeText(awardUrl);
      setResultMsg("Link copied to your clipboard");
    } else {
      setResultMsg(
        "Unable to copy link to clipboard - try manually copying it instead"
      );
    }

    setShowResultMsg(true);
  };

  const handlePreviewButtonClick = () => {
    window.open(awardUrl, "_blank");

    //@ts-ignore
    window.gtag("event", "feature_use", {
      event_category: "preview",
      event_label: "preview",
    });
  };

  const handleSaveImage = () => {
    const targetElement = screenshotRef.current;

    //@ts-ignore
    window.gtag("event", "feature_use", {
      event_category: "share",
      event_label: "Save Image",
    });

    if (targetElement) {
      // Peculiarity - "scale: 2" needs to be there or the colour of the image
      // generated on some devices will be inverted.
      html2canvas(targetElement, { scale: 2 })
        .then((canvas) => {
          const screenshotImage = canvas.toDataURL("image/png");
          downloadUri(
            screenshotImage,
            awardName +
              (awardParams?.awardeeName
                ? " - " + awardParams.awardeeName
                : "")
          );
        })
        .catch((err) => {
          setResultMsg(
            "Unable to download image. Try Preview and screenshot instead."
          );
          setShowResultMsg(true);
        });
    }
  };

  const handleCopyImage = () => {
    const targetElement = screenshotRef.current;

    //@ts-ignore
    window.gtag("event", "feature_use", {
      event_category: "share",
      event_label: "Copy Image",
    });

    if (targetElement) {
      // Peculiarity - "scale: 2" needs to be there or the colour of the image
      // generated on some devices will be inverted.
      html2canvas(targetElement, { scale: 2 })
        .then((canvas) => {
          canvas.toBlob((blob) =>
            navigator.clipboard.write([
              new ClipboardItem({ "image/png": blob }),
            ])
          );
          setResultMsg(
            "Image copied to clipboard. Paste directly to use."
          );
          setShowResultMsg(true);
        })
        .catch((err) => {
          setResultMsg(
            "Unable to copy image. Try Preview and screenshot instead."
          );
          setShowResultMsg(true);
        });
    }
  };

  return (
    <>
      <Card
        sx={{
          m: 2,
          p: 2,
          width: "100%",
          maxWidth: 600,
          minWidth: 300,
        }}
      >
        <Logo />
        <TextField
          label="Award Name *"
          autoComplete="off"
          autoCapitalize="words"
          fullWidth
          margin="dense"
          value={awardName}
          onChange={(event) => setAwardName(event.target.value)}
          InputLabelProps={{ shrink: true }}
          placeholder={`e.g. ${placeholders[placeholderChoice].awardName}`}
        />
        <TextField
          label="Awardee Name"
          autoComplete="off"
          fullWidth
          margin="dense"
          value={awardParams.awardeeName}
          InputLabelProps={{ shrink: true }}
          placeholder="e.g., John Doe"
          onChange={(event) =>
            setAwardParams((prev) => {
              return { ...prev, awardeeName: event.target.value };
            })
          }
        />

        <Box
          onClick={() => setShowOptionalSettings((prev) => !prev)}
          component="button"
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 1,
            mb: 1,
            cursor: "pointer",
            color: theme.palette.text.secondary,
            backgroundColor: "transparent",
            border: "0",
          }}
        >
          <Typography fontWeight={600} fontSize="0.9rem">
            Other Optional Settings
          </Typography>
          <ExpandMore
            sx={{
              transform: !showOptionalSettings
                ? "rotate(0deg)"
                : "rotate(180deg)",
              transition: "all 0.2s",
            }}
          />
        </Box>
        <Collapse in={showOptionalSettings}>
          <Box
            sx={{
              backgroundColor: "#F5F5F5",
              pt: 2,
              pl: 2,
              pr: 2,
              mb: 1,
              borderRadius: "4px",
            }}
          >
            <TextField
              label="Awarding Authority"
              autoComplete="off"
              fullWidth
              margin="dense"
              value={awardParams.awarderName}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleRandomAwarderName}
                      edge="end"
                    >
                      <Assistant />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder={`e.g. ${placeholders[placeholderChoice].awarderName}`}
              onChange={(event) =>
                setAwardParams((prev) => {
                  return { ...prev, awarderName: event.target.value };
                })
              }
            />
            <DatePicker
              label="Award Date"
              format="DD/MM/YYYY"
              value={awardParams.awardDate}
              onChange={(newValue) =>
                setAwardParams((prev) => {
                  return { ...prev, awardDate: newValue };
                })
              }
              slotProps={{
                textField: {
                  InputLabelProps: { shrink: true },
                },
              }}
              sx={{ width: "100%", mt: 1, mb: 0.5 }}
            />
            <FormControl fullWidth sx={{ mt: 1, mb: 0.5 }}>
              <InputLabel id="award-type-select-label" shrink>
                Award Type
              </InputLabel>
              <Select
                labelId="award-type-select-label"
                label="Award Type"
                notched
                value={awardParams.awardType}
                displayEmpty
                onChange={(event) =>
                  setAwardParams((prev) => {
                    return { ...prev, awardType: event.target.value };
                  })
                }
              >
                <MenuItem value="">Any - it doesn't matter</MenuItem>
                <MenuItem value="DUNDIES">Dundies</MenuItem>
                <MenuItem value="TROPHIES">Trophies</MenuItem>
                <MenuItem value="OSCARS">Oscars</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="text"
              size="small"
              color="inherit"
              fullWidth
              onClick={() =>
                setAwardParams((prev) => {
                  return {
                    ...prev,
                    awarderName: "",
                    awardDate: null,
                    awardType: "",
                  };
                })
              }
              sx={{ color: "#666666", mb: 1 }}
            >
              Clear All Optional
            </Button>
          </Box>
        </Collapse>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <Button
              variant="text"
              onClick={handlePreviewButtonClick}
              startIcon={<TravelExplore />}
              disabled={!awardName}
              fullWidth
            >
              Preview
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="text"
              onClick={handleCopyButtonClick}
              startIcon={<LinkIcon />}
              disabled={!awardName}
              fullWidth
            >
              Get Award Link
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ShareButtonWithDialog
              awardUrl={awardUrl}
              awardName={awardName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="text"
              onClick={handleSaveImage}
              startIcon={<Download />}
              disabled={!awardName}
              fullWidth
            >
              Save Award Image
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="text"
              onClick={handleCopyImage}
              startIcon={<Image />}
              disabled={!awardName}
              fullWidth
            >
              Copy Award Image
            </Button>
          </Grid>
        </Grid>

        <Snackbar
          open={showResultMsg}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          message={resultMsg}
          onClose={() => setShowResultMsg(false)}
          autoHideDuration={1000}
        />
      </Card>

      <Box
        ref={screenshotRef}
        sx={{
          position: "absolute",
          top: "-150vh",
          left: "-150vw",
          width: "500px",
        }}
      >
        <AwardView
          awardName={awardName}
          awardParams={{
            ...awardParams,
            awardDate: awardParams.awardDate?.format("DD MMMM YYYY"),
          }}
        />
      </Box>
    </>
  );
}
