import {
  Box,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useMemo } from "react";
import "./App.css";
import AwardForm from "./components/AwardForm";
import AwardView from "./components/AwardView";
import { base64ToBytes } from "./utils";

const theme = createTheme({
  typography: {
    // @ts-ignore
    awardName: {
      fontSize: "2rem",
      fontFamily: "'Bahnschrift', 'Roboto', sans-serif",
      letterSpacing: "1px",
      lineHeight: "1",
      fontWeight: 600,
      marginTop: "2px",
      marginBottom: "8px",
    },
    awardeeName: {
      fontSize: "1.3rem",
      fontFamily: "'Roboto', sans-serif",
      fontWeight: 600,
      paddingTop: "8px",
      paddingBottom: "8px",
      marginTop: "8px",
      marginBottom: "8px",
      backgroundColor: "#F0F0F0",
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          // @ts-ignore
          awardName: "div",
          awardeeName: "div",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#d50000",
    },
    secondary: {
      main: "#66bb6a",
    },
  },
});

function App() {
  const awardName = useMemo(
    () =>
      decodeURIComponent(window.location.pathname)
        .replaceAll("/", "")
        .replaceAll("_", " "),
    []
  );
  const awardParams = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.has("p")) return null;

    try {
      const decodedParams = new TextDecoder().decode(
        base64ToBytes(urlParams.get("p"))
      );

      return JSON.parse(decodedParams);
    } catch (e) {
      return null;
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          sx={{
            width: "100%",
            height: "min-content",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
          }}
        >
          {awardName && (
            <Stack
              spacing={2}
              sx={{
                boxSizing: "border-box",
                p: 2,
              }}
            >
              <AwardView
                awardName={awardName}
                awardParams={awardParams}
              />

              <Box
                sx={{
                  width: "100%",
                  maxWidth: 500,
                  minWidth: 300,
                  textAlign: "center",
                  p: 1,
                  "> *": {
                    ml: "auto",
                    mr: "auto",
                  },
                }}
              >
                <Typography variant="body1">
                  Want to award someone a totally legitimate award
                  too?{" "}
                  <a
                    href={window.location.origin}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click here
                  </a>
                </Typography>
              </Box>
            </Stack>
          )}

          {!awardName && <AwardForm />}
        </Box>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
