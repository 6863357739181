// @ts-nocheck
/**
 * Takes a base64 encoded string and return the corresponding bytes
 *
 * @param {string} base64 base64 encodeded byte array of unicode string
 * @returns bytes
 */
export function base64ToBytes(base64) {
  const binString = atob(base64);
  return Uint8Array.from(binString, (m) => m.codePointAt(0));
}

/**
 * Takes the bytes from a unicode string and returns a base64 encoded string.
 *
 * @param {bytes} inputBytes
 * @returns base64 encoded string
 */
export function bytesToBase64(inputBytes) {
  const binString = String.fromCodePoint(...inputBytes);
  return btoa(binString);
}

/**
 * Takes a seed and returns a pseudo random number between 0-1 based on it.
 *
 * @param {number} seed
 * @returns {number} between 0-1
 */
export function pseudoRandom(seed) {
  var t = (seed += 0x6d2b79f5) % Number.MAX_SAFE_INTEGER;
  t = Math.imul(t ^ (t >>> 15), t | 1);
  t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
  return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
}

/**
 * Takes a string and returns a message digest that is converted to integer
 *
 * @param {string} text
 * @returns
 */
export async function hashTextToNum(text) {
  const encoder = new TextEncoder();
  const data = encoder.encode(text);

  const hashBuffer = await crypto.subtle.digest("SHA-256", data);

  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");

  const hashNumber = parseInt(hashHex, 16);

  return hashNumber;
}

/**
 * Programmatically initiates a download given the URI
 *
 * @param {string} uri file path
 * @param {string} name filename
 */
export function downloadUri(uri, name) {
  const link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
