import { Box, Typography } from "@mui/material";
import React from "react";

export const color1 = "#40476D";
export const color2 = "#931621";
export const fontFamily = "'Bahnschrift', 'Roboto', sans-serif";

export default function Logo({ inline = false, ...props }) {
  return (
    <>
      {inline && (
        <Typography
          component="span"
          variant="inherit"
          fontFamily={fontFamily}
          fontSize="1.05em"
          letterSpacing="-0.05em"
          {...props}
        >
          <Typography
            color={color1}
            variant="inherit"
            component="span"
            fontSize="0.8em"
          >
            the
          </Typography>
          <Typography
            color={color2}
            variant="inherit"
            component="span"
          >
            DUMDIES
          </Typography>
        </Typography>
      )}

      {!inline && (
        <Box
          sx={{ display: "flex", justifyContent: "center", mb: 1 }}
        >
          <Box>
            <Typography
              fontWeight={500}
              fontSize={48}
              fontFamily={fontFamily}
              component="div"
              textAlign="center"
              letterSpacing="-0.05em"
              sx={{ lineHeight: 0.8 }}
            >
              <Typography
                variant="inherit"
                sx={{ color: color1 }}
                component="span"
                fontSize="0.8em"
              >
                the
              </Typography>
              <Typography
                variant="inherit"
                sx={{ color: color2 }}
                component="span"
              >
                DUMDIES
              </Typography>
            </Typography>
            <Typography
              variant="caption"
              textAlign="center"
              component="div"
              gutterBottom
            >
              give someone a totally legitimate award
            </Typography>
          </Box>
          <Box>
            <img
              src="./logo192.png"
              width="54"
              alt="the Dumdie award"
            />
          </Box>
        </Box>
      )}
    </>
  );
}
