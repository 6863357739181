import { Box, Card, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { hashTextToNum, pseudoRandom } from "../utils";
import Logo from "./Logo";

const TROPHIES = [
  "award_0.jpeg",
  "award_1.jpeg",
  "award_2.jpeg",
  "award_3.jpeg",
  "award_4.jpeg",
  "award_5.jpeg",
  "award_7.jpeg",
  "award_8.jpeg",
  "award_9.jpeg",
  "award_10.jpeg",
  "award_11.jpeg",
  "award_12.jpeg",
  "award_13.jpeg",
  "award_15.jpeg",
  "award_16.jpeg",
  "award_17.jpeg",
  "award_19.jpeg",
  "award_21.jpeg",
  "award_22.jpeg",
  "award_23.jpeg",
  "award_24.jpeg",
  "award_25.jpeg",
  "award_26.jpeg",
  "award_27.jpeg",
  "award_28.jpeg",
  "award_29.jpeg",
  "award_30.jpeg",
  "award_31.jpeg",
  "award_32.jpeg",
  "award_33.jpeg",
  "award_34.jpeg",
  "award_35.jpeg",
  "award_36.jpeg",
];
const DUNDIES = [
  "award_37.jpeg",
  "award_38.jpeg",
  "award_39.jpeg",
  "award_40.jpeg",
  "award_41.jpeg",
];
const OSCARS = [
  "award_6.jpeg",
  "award_14.jpeg",
  "award_18.jpeg",
  "award_20.jpeg",
];

const ANY = [...TROPHIES, ...DUNDIES, ...OSCARS];

const AWARDS = {
  TROPHIES: TROPHIES,
  DUNDIES: DUNDIES,
  OSCARS: OSCARS,
  ANY: ANY,
};

export default function AwardView({ awardName, awardParams }) {
  const [imageChosen, setImageChosen] = useState(null);
  const sanitisedAwardName = useMemo(() => {
    return awardName.replaceAll("/", "").replaceAll("_", " ");
  }, [awardName]);

  useEffect(() => {
    let isActive = true;

    (async () => {
      if (!isActive) return;

      const numHash = await hashTextToNum(
        sanitisedAwardName.toUpperCase()
      );
      const imageCat = awardParams?.awardType
        ? awardParams.awardType
        : "ANY";
      setImageChosen(
        AWARDS[imageCat][
          Math.floor(pseudoRandom(numHash) * AWARDS[imageCat].length)
        ]
      );
    })();

    return () => {
      isActive = false;
    };
  }, [sanitisedAwardName, awardParams]);

  return (
    <Card
      sx={{
        width: "100%",
        maxWidth: 500,
        minWidth: 300,
        boxSizing: "border-box",
        pt: 1,
        pl: 1,
        pr: 1,
        textAlign: "center",
        "> *": {
          ml: "auto",
          mr: "auto",
        },
      }}
    >
      {imageChosen && (
        <>
          <Box
            component="img"
            src={`/images/${imageChosen}`}
            alt="award trophy"
            sx={{
              width: "100%",
              minWidth: 200,
              maxWidth: "100%",
              borderRadius: "4px",
            }}
          ></Box>

          {awardParams?.awardeeName && (
            <>
              <Typography variant="body1">
                ...and the one and only
              </Typography>
              <Typography
                // @ts-ignore
                variant="awardName"
              >
                {sanitisedAwardName.toUpperCase()}
              </Typography>

              {(awardParams?.awarderName ||
                awardParams?.awardDate) && (
                <Typography variant="body1">
                  has been awarded to
                </Typography>
              )}

              {!awardParams?.awarderName &&
                !awardParams?.awardDate && (
                  <Typography variant="body1">
                    goes to none other than
                  </Typography>
                )}

              <Typography
                // @ts-ignore
                variant="awardeeName"
              >
                {awardParams.awardeeName}
              </Typography>
            </>
          )}

          {!awardParams?.awardeeName && (
            <>
              <Typography variant="body1">
                Congratulations! You have just been awarded the
              </Typography>
              <Typography
                // @ts-ignore
                variant="awardName"
              >
                {sanitisedAwardName.toUpperCase()}
              </Typography>
            </>
          )}

          {awardParams?.awarderName && (
            <Typography variant="body1">
              by {awardParams.awarderName}
            </Typography>
          )}

          {awardParams?.awardDate && (
            <Typography variant="body1">
              on {awardParams.awardDate}
            </Typography>
          )}
        </>
      )}
      <Typography
        variant="caption"
        component="div"
        sx={{ mt: 1, mb: 0, textAlign: "right" }}
      >
        <Logo inline sx={{ verticalAlign: "bottom" }} />
        .com
      </Typography>
    </Card>
  );
}
